<!--
-->

<template>
  <div ref="rootNode" />
  <div />
</template>

<script>
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { defineModel, emits, inject, onBeforeUnmount, onMounted, ref, toRaw, watch } from 'vue';
import { gameStore } from "@/stores/game";
//import { gameStore } from "@/stores/state";
import * as THREE from 'three';

export default {
    name: 'Transitions',
      "components": {
      },
      "props": {
      },
      "emits": [
      ],
      setup (props, {emit}) {

        const scene = inject('scene'),
        game = gameStore(),
        camera = inject('camera'),
        mouseInfo = inject('mouseInfo'),
        rootNode = ref(),
        process = () => {

           const t = game.getState("TRANSITIONS");

           for (const i in t) {

              // set defaults if not set.
              t[i].ease = t[i].ease || true;
              t[i].alpha = t[i].alpha || 0.1;

              if (t[i]?.position) {

                  const distance = t[i].node.position.distanceTo(t[i].position);

                  if (distance >= t[i].alpha/2) {

                    t[i].node.position.lerp(t[i].position, t[i].alpha);

                  } else {

                    t[i].node.position.copy(t[i].position);
                    game.remTransition(t[i].id);

                  }

              }

              if (t[i]?.quaternion) {

                  t[i].node.quaternion.slerp(t[i].quaternion, t[i].alpha);

                  let epsilon = t[i].alpha/10;

                  if ( ( Math.abs( t[i].node.quaternion.x - t[i].quaternion.x ) < epsilon )
                  && ( Math.abs( t[i].node.quaternion.y - t[i].quaternion.y ) < epsilon )
                  && ( Math.abs( t[i].node.quaternion.z - t[i].quaternion.z ) < epsilon )
                  && ( Math.abs( t[i].node.quaternion.w - t[i].quaternion.w ) < epsilon ) ) {

                    t[i].node.quaternion.copy(t[i].quaternion);
                    game.remTransition(t[i].id);

                }

              }

           }

        },
        cardUi = () => {};

        onMounted(() => {

        });

        onBeforeUnmount(() => {

        });

        return {
          scene,
            game,
            camera,
            mouseInfo,
            rootNode,
            process
        };

      }

  };
</script>

<style>
body {
margin: 0;
}

</style>
