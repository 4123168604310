<template>
  <div ref="rootNode">
  </div>
</template>

<script>
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { ref, toRaw, emits, inject, watch, defineExpose, onMounted, onBeforeUnmount } from 'vue';

export default {
  name: 'Physicss',
  "props": {
      "entities": Object
  },
  "emits": [
      "add-to-scene"
  ],
  setup (props, {emit}) {

    var rigidBody = {};
    var sphereBody;
    var boxBody;

    const rootNode = ref(),
    Ammo = inject('Ammo'),
    THREE = inject('THREE'),
    STATE = ref({
        ACTIVE_TAG: 1,
        ISLAND_SLEEPING: 2,
        WANTS_DEACTIVATION: 3,
        DISABLE_DEACTIVATION: 4,
       	DISABLE_SIMULATION: 5 
    }),
    COLLISION_FLAGS = ref({
        CF_STATIC_OBJECT: 1,
        CF_KINEMATIC_OBJECT: 2,
        CF_NO_CONTACT_RESPONSE: 4,
        CF_CUSTOM_MATERIAL_CALLBACK: 8,
        CF_CHARACTER_OBJECT: 16,
        CF_DISABLE_VISUALIZE_OBJECT: 32,
        CF_DISABLE_SPU_COLLISION_PROCESSING: 64,
        CF_HAS_CONTACT_STIFFNESS_DAMPING: 128,
        CF_HAS_CUSTOM_DEBUG_RENDERING_COLOR: 256,
        CF_HAS_FRICTION_ANCHOR: 512,
        CF_HAS_COLLISION_SOUND_TRIGGER: 1024
    }),
    doRender = ref(true),
    entities = ref(props.entities),
    stateNodes = ref({}),
    width = ref(),
    height = ref(),
    gravity = ref(-9.81),
	  tStep = ref(1/1000),
	  tLast = ref(0.0),
	  tIntegrate = ref(0.0),
    currentTime = ref(new Date()),
    deltaT = ref(0),
    stepInterval = ref(0.01),
    accumulator = ref(0),
    counter = ref(new Date()),
    iterations = ref(0),
    lastTickIterations = ref(0),
    physicsWorld = ref(),
    rigidBodyCount = ref(0),
    box = ref(),
    showPhysics = ref(false),
    init = (n) => {

      const collisionConfiguration = new Ammo.value.btDefaultCollisionConfiguration();
      const dispatcher = new Ammo.value.btCollisionDispatcher(collisionConfiguration);
      const broadphase = new Ammo.value.btDbvtBroadphase();
      const solver = new Ammo.value.btSequentialImpulseConstraintSolver();
      physicsWorld.value = new Ammo.value.btDiscreteDynamicsWorld(dispatcher, broadphase, solver, collisionConfiguration);
      physicsWorld.value.setGravity(new Ammo.value.btVector3(0, -10, 0));

    },
    createGround = () => {

      const groundShape = new Ammo.value.btStaticPlaneShape(new Ammo.value.btVector3(0, 1, 0), -1); 
      const groundTransform = new Ammo.value.btTransform();
      groundTransform.setIdentity();
      groundTransform.setOrigin(new Ammo.value.btVector3(0, 1, 0));
      const groundMass = 0;
      const groundLocalInertia = new Ammo.value.btVector3(0, 0, 0);
      const groundMotionState = new Ammo.value.btDefaultMotionState(groundTransform);
      const groundBodyInfo = new Ammo.value.btRigidBodyConstructionInfo(groundMass, groundMotionState, groundShape, groundLocalInertia);
      groundBodyInfo.m_friction = 0.8;
      const groundBody = new Ammo.value.btRigidBody(groundBodyInfo);

      physicsWorld.value.addRigidBody(groundBody);

/*
      // Create ground
      const groundShape = new Ammo.value.btBoxShape(new Ammo.value.btVector3(50, 1, 50));
      const groundTransform = new Ammo.value.btTransform();
      groundTransform.setIdentity();
      groundTransform.setOrigin(new Ammo.value.btVector3(0, 0, 0));
      const groundMass = 0;
      const groundLocalInertia = new Ammo.value.btVector3(0, 0, 0);
      const groundMotionState = new Ammo.value.btDefaultMotionState(groundTransform);
      const groundBodyInfo = new Ammo.value.btRigidBodyConstructionInfo(groundMass, groundMotionState, groundShape, groundLocalInertia);
      const groundBody = new Ammo.value.btRigidBody(groundBodyInfo);
      physicsWorld.value.addRigidBody(groundBody);
*/
    },
    createRigidBody = (node, shape, mass, inertia, kinematic) => {

        console.log("createRigidBody kinematic : " + kinematic);

        if (shape?.type == 'box') {

            createBox(node, shape, mass, inertia, kinematic);

        }

        if (shape?.type == 'sphere') {

            createSphere(node, shape, mass, inertia, kinematic);

        }

    },
    createBox = (node, shape, mass, inertia, kinematic) => {

      var globalPosition = new THREE.Vector3();

      node.getWorldPosition(globalPosition);
      // node.localToWorld(globalPosition);

      var globalQuaternion = new THREE.Quaternion();
      node.getWorldQuaternion(globalQuaternion);

      let position = globalPosition;
      let rotation = globalQuaternion;
      var size;

      if (!position) {

        position = new THREE.Vector3(0,0,0);

      }

      if (!mass) {

        mass = 1;

      }

      if (!inertia) {

        inertia = {x:0,y:0,z:0}

      }

      var initialRotationQuaternion;

      // Create box

      if (shape?.box?.size) {

          size = new THREE.Vector3(shape.box.size[0],shape.box.size[1],shape.box.size[2]);

          // need to talk to eoin @ khronos about this

          if (size.x == 2 && size.y == 2) {


              var box = new THREE.Box3().setFromObject(node, true);
              size = new THREE.Vector3();
              box.getSize(size);

          }

      } else {

          var box = new THREE.Box3().setFromObject(node, true);
          size = new THREE.Vector3();
          box.getSize(size);

      }

      const btShape = new Ammo.value.btBoxShape(new Ammo.value.btVector3(size.x/2, size.y/2, size.z/2));
      // TEST TODO DELME
      btShape.setMargin(0.1);

      const btTransform = new Ammo.value.btTransform();
      btTransform.setIdentity();


      if (position) {

        btTransform.setOrigin(new Ammo.value.btVector3(position.x, position.y, position.z));

      }

      if (rotation) {

        initialRotationQuaternion = new Ammo.value.btQuaternion(rotation.x,rotation.y,rotation.z,rotation.w);
        initialRotationQuaternion.normalize();
        btTransform.setRotation(initialRotationQuaternion);

      } else {

        initialRotationQuaternion = new Ammo.value.btQuaternion();

      }

      const localInertia = new Ammo.value.btVector3(inertia.x,inertia.y,inertia.z);
      btShape.calculateLocalInertia(mass, localInertia);

      const motionState = new Ammo.value.btDefaultMotionState(btTransform);
      const btBodyInfo = new Ammo.value.btRigidBodyConstructionInfo(mass, motionState, btShape, localInertia);

      //btBodyInfo.setContactStiffness( 0.2 );
      //btBodyInfo.setContactDamping( 0.8 );

      btBodyInfo.m_friction = 0.7;
      btBodyInfo.m_restitution = 0.1;
      btBodyInfo.m_linearDamping = 0.2;
      btBodyInfo.m_angularDamping = 0.2;

      rigidBody[rigidBodyCount.value] = new Ammo.value.btRigidBody(btBodyInfo);
      rigidBody[rigidBodyCount.value].setCenterOfMassTransform(btTransform);
      physicsWorld.value.addRigidBody(rigidBody[rigidBodyCount.value]);

      if (kinematic == true) {

        setKinematic(rigidBody[rigidBodyCount.value]);

      }

      const geometry = new THREE.BoxGeometry(size.x, size.y, size.z);
      const material = new THREE.MeshLambertMaterial({ color: 0x02ced9 });
      const cubeMesh = new THREE.Mesh(geometry, material);
      const physicsNode = new THREE.Object3D();

      physicsNode.position.set(0,0,0);
      physicsNode.add(cubeMesh);
      physicsNode.name = "physicsNode" + rigidBodyCount.value;
      physicsNode.userData.isPhysicsNode = true;
      physicsNode.userData.isKinematic = kinematic;
      physicsNode.userData.rigidBodyId = rigidBodyCount.value;
      physicsNode.visible = showPhysics.value;

      node.userData.hasPhysics = true;
      node.userData.rigidBodyId = rigidBodyCount.value;
      node.add(physicsNode);

      // turn off visibility.
      if (node.parent.type == "Bone") {

          node.visible = false;

      }

      // store a reference to the node for updates
      stateNodes.value[rigidBodyCount.value] = physicsNode;
      rigidBodyCount.value++;

    },
    createSphere = (node, shape, mass, inertia, kinematic) => {

      var globalPosition = new THREE.Vector3();
      node.getWorldPosition(globalPosition);
      //node.localToWorld(globalPosition);

      var globalQuaternion = new THREE.Quaternion();
      node.getWorldQuaternion(globalQuaternion);

      let position = globalPosition;
      let rotation = globalQuaternion;
      var size;

      if (!position) {

        position = new THREE.Vector3(0,0,0);

      }

      if (!mass) {

        mass = 1;

      }

      if (!inertia) {

        inertia = {x:0,y:0,z:0}

      }

      var initialRotationQuaternion;

      // Create sphere

      if (shape?.sphere?.radius) {

          size = shape.sphere.radius;

      } else {

          var box = new THREE.Box3().setFromObject(node, true);
          size = new THREE.Vector3();
          box.getSize(size);

      }

      const btShape = new Ammo.value.btSphereShape(size);
      const btTransform = new Ammo.value.btTransform();
      btTransform.setIdentity();

      if (position) {

        btTransform.setOrigin(new Ammo.value.btVector3(position.x, position.y, position.z));

      }

      if (rotation) {

        initialRotationQuaternion = new Ammo.value.btQuaternion(rotation.x,rotation.y,rotation.z,rotation.w);
        initialRotationQuaternion.normalize();
        btTransform.setRotation(initialRotationQuaternion);

      } else {

        initialRotationQuaternion = new Ammo.value.btQuaternion();

      }

      const localInertia = new Ammo.value.btVector3(inertia.x,inertia.y,inertia.z);
      btShape.calculateLocalInertia(mass, localInertia);

      const motionState = new Ammo.value.btDefaultMotionState(btTransform);
      const btBodyInfo = new Ammo.value.btRigidBodyConstructionInfo(mass, motionState, btShape, localInertia);

      btBodyInfo.m_friction = 0.7;
      btBodyInfo.m_restitution = 0.1;
      btBodyInfo.m_linearDamping = 0.2;
      btBodyInfo.m_angularDamping = 0.2;

      rigidBody[rigidBodyCount.value] = new Ammo.value.btRigidBody(btBodyInfo);
      rigidBody[rigidBodyCount.value].setCenterOfMassTransform(btTransform);
      physicsWorld.value.addRigidBody(rigidBody[rigidBodyCount.value]);

      const impulse = new Ammo.value.btVector3(inertia.x, inertia.y, inertia.z);
      rigidBody[rigidBodyCount.value].applyCentralImpulse(impulse);

      if (kinematic == true) {

        setKinematic(rigidBody[rigidBodyCount.value]);

      }

      const geometry = new THREE.SphereGeometry(size);
      const material = new THREE.MeshLambertMaterial({ color: 0x02ced9 });
      const cubeMesh = new THREE.Mesh(geometry, material);
      const physicsNode = new THREE.Object3D();

      physicsNode.position.set(0,0,0);
      physicsNode.add(cubeMesh);
      physicsNode.name = "physicsNode" + rigidBodyCount.value;
      physicsNode.userData.isPhysicsNode = true;
      physicsNode.userData.isKinematic = kinematic;
      physicsNode.userData.rigidBodyId = rigidBodyCount.value;
      physicsNode.visible = showPhysics.value;

      node.userData.hasPhysics = true;
      node.userData.rigidBodyId = rigidBodyCount.value;
      node.add(physicsNode);

      // turn off visibility.
      if (node.parent.type == "Bone") {

          node.visible = false;

      }

      // store a reference to the node for updates
      stateNodes.value[rigidBodyCount.value] = physicsNode;
      rigidBodyCount.value++;

    },
/*
    createBox = (node, size, mass, inertia, kinematic) => {

      // Create Box
      const btShape = new Ammo.value.btBoxShape(new Ammo.value.btVector3(size.x/2, size.y/2, size.z/2));
      const btTransform = new Ammo.value.btTransform();
      btTransform.setIdentity();
      btTransform.setOrigin(new Ammo.value.btVector3(node.position.x, node.position.y, node.position.z));
      const boxMass = 1;
      const localInertia = new Ammo.value.btVector3(0, 0, 0);
      btShape.calculateLocalInertia(mass, localInertia);
      const motionState = new Ammo.value.btDefaultMotionState(btTransform);
      const btBodyInfo = new Ammo.value.btRigidBodyConstructionInfo(mass, motionState, btShape, localInertia);
      rigidBody[rigidBodyCount.value] = new Ammo.value.btRigidBody(btBodyInfo);
      physicsWorld.value.addRigidBody(rigidBody[rigidBodyCount.value]);

      var geometry = new THREE.BoxGeometry(size.x, size.y, size.z);
      var material = new THREE.MeshLambertMaterial({ color: 0x02ced9 });
      var cubeMesh = new THREE.Mesh(geometry, material);
      var physicsNode = new THREE.Object3D();
      physicsNode.position.set(0,0,0);
      physicsNode.add(cubeMesh);
      physicsNode.name = "physicsNode" + rigidBodyCount.value;
      physicsNode.userData.isPhysicsNode = true;
      physicsNode.userData.rigidBodyId = rigidBodyCount.value;
      physicsNode.visible = false;

      node.userData.hasPhysics = true;
      node.userData.rigidBodyId = rigidBodyCount.value;;
      node.add(physicsNode);

      // store a reference to the node for updates
      stateNodes.value[rigidBodyCount.value] = physicsNode;

      rigidBodyCount.value++;

      emit("add-to-scene", {
        "node": node,
        "name": rigidBodyCount.value - 1,
        "type": "box",
        "size": size,
        "position": node.position,
        "rotation": node.quaternion,
        "rigidBody": rigidBodyCount.value - 1
      });

    },
    createSphere = (node, size, mass, inertia) => {

      const sphereShape = new Ammo.value.btSphereShape(new Ammo.value.btVector3(size));
      const sphereTransform = new Ammo.value.btTransform();
      sphereTransform.setIdentity();
      sphereTransform.setOrigin(new Ammo.value.btVector3(node.position.x, node.position.y, node.position.z));
      const sphereMass = 2;
      const sphereLocalInertia = new Ammo.value.btVector3(0, 0, 0);
      sphereShape.calculateLocalInertia(mass, sphereLocalInertia);
      const sphereMotionState = new Ammo.value.btDefaultMotionState(sphereTransform);
      const sphereBodyInfo = new Ammo.value.btRigidBodyConstructionInfo(mass, sphereMotionState, sphereShape, sphereLocalInertia);
      rigidBody[rigidBodyCount.value] = new Ammo.value.btRigidBody(sphereBodyInfo);
      physicsWorld.value.addRigidBody(rigidBody[rigidBodyCount.value]);

      var sphereGeometry = new THREE.BoxGeometry(size.x, size.y, size.z);
      var material = new THREE.MeshLambertMaterial({ color: 0x02ced9 });
      var cubeMesh = new THREE.Mesh(sphereGeometry, material);
      var physicsNode = new THREE.Object3D();
      physicsNode.position.set(0,0,0);
      physicsNode.add(cubeMesh);
      physicsNode.name = "physicsNode" + rigidBodyCount.value;
      physicsNode.userData.isPhysicsNode = true;
      physicsNode.userData.rigidBodyId = rigidBodyCount.value;
      physicsNode.visible = false;

      node.userData.hasPhysics = true;
      node.userData.rigidBodyId = rigidBodyCount.value;;
      node.add(physicsNode);

      // store a reference to the node for updates
      stateNodes.value[rigidBodyCount.value] = physicsNode;

      rigidBodyCount.value++;

      emit("add-to-scene", {
        "node": node,
        "name": rigidBodyCount.value - 1,
        "type": "sphere",
        "size": size,
        "position": node.position,
        "rotation": node.quaternion,
        "rigidBody": rigidBodyCount.value - 1
      });

    },
*/
    addRigidBody = (n) => {

    },
    removeRigidBody = (n) => {

    },
    setKinematic = (body) => {

      body.setActivationState( STATE.value.DISABLE_DEACTIVATION );
      body.setCollisionFlags( COLLISION_FLAGS.value.CF_KINEMATIC_OBJECT );
      // body.setCollisionFlags( COLLISION_FLAGS.value.CF_CHARACTER_OBJECT );

    },
    setDynamic = (body) => {

      body.setActivationState(STATE.value.ACTIVE);
      body.setCollisionFlags(COLLISION_FLAGS.value.CF_DYNAMIC_OBJECT);

    },
    togglePhysicsVisibility = () => {

      if (showPhysics.value == true) {

          showPhysics.value = false;

      } else {

          showPhysics.value = true;

      }

      for (var i in stateNodes.value) {

          stateNodes.value[i].visible = showPhysics.value;

          // also for rigged rigid bodies
          if (stateNodes.value[i].parent?.parent?.type == "Bone") {

              stateNodes.value[i].parent.visible = showPhysics.value;

          }

      }

    },
    render = () => {

      if (((new Date() - counter.value)/1000) >= 1) {

          // console.log(iterations.value - lastTickIterations.value + ' physics iterations by second')
          lastTickIterations.value = iterations.value;
          counter.value = new Date();

      }

      const newTime = new Date();
      deltaT.value = (newTime - currentTime.value) / 1000;
      currentTime.value = newTime;
      accumulator.value += deltaT.value;

      // console.log('Physics.vue : deltaT ' + deltaT.value);
      // console.log('Physics.vue : accumulator.value ' + accumulator.value);

      if (accumulator.value >= stepInterval.value) {

        iterations.value++;

        accumulator.value -= stepInterval.value;

        // fixed timestep 
        physicsWorld.value.stepSimulation(stepInterval.value);

        for (var i in stateNodes.value) {

          const rB = rigidBody[stateNodes.value[i].userData.rigidBodyId];
          const motionState = rB.getMotionState();

          // update kinetic animations
          if (stateNodes.value[i].userData.isKinematic == true) {

            let trans = new Ammo.value.btTransform();

    //        I'm leaving this here for posterity, this is how you may attempt to do it at first glance
    //        However getWorldQuaternion is a disaster in performance, instead extrapolate from worldMatrix
    //        var position = new THREE.Vector3();
    //        stateNodes.value[i].getWorldPosition(position);

    //        THIS IS INSANELY SLOW
    //        var rotation = new THREE.Quaternion();
    //        stateNodes.value[i].getWorldQuaternion(rotation);

            var worldMatrix = stateNodes.value[i].matrixWorld;
            var position = new THREE.Vector3();
            var scale = new THREE.Vector3();
            var rotation = new THREE.Quaternion();
            worldMatrix.decompose(position, rotation, scale);

            //let btPosition = new Ammo.value.btVector3(position.x, position.y, position.z);
            //let btRotation = new Ammo.value.btQuaternion(rotation.x, rotation.y, rotation.z, rotation.w);

            trans.setOrigin(new Ammo.value.btVector3(position.x, position.y, position.z));
            trans.setRotation(new Ammo.value.btQuaternion(rotation.x, rotation.y, rotation.z, rotation.w));

            if (motionState) {

                motionState.setWorldTransform(trans);
                rB.setWorldTransform(trans);

            }

            //Ammo.value.destroy(btPosition);
            //Ammo.value.destroy(btRotation);
            Ammo.value.destroy( trans );

          } else {

            if (motionState) {

              let trans = new Ammo.value.btTransform();
              motionState.getWorldTransform(trans);

              // rotation
              const rotation = trans.getRotation();
              const globalRotation = new THREE.Quaternion(rotation.x(), rotation.y(), rotation.z(), rotation.w());

              let parentGlobalRotation = new THREE.Quaternion();
              stateNodes.value[i].parent.parent.getWorldQuaternion(parentGlobalRotation);

              let localTransform = new THREE.Quaternion();
              localTransform.copy(globalRotation).multiply(parentGlobalRotation.invert());

              stateNodes.value[i].parent.setRotationFromQuaternion(localTransform);

              // position
              const position = trans.getOrigin();
              let btGlobalPosition = new THREE.Vector3(position.x(),position.y(),position.z());

              stateNodes.value[i].parent.parent.worldToLocal(btGlobalPosition);
              stateNodes.value[i].parent.position.set(btGlobalPosition.x, btGlobalPosition.y, btGlobalPosition.z);

              Ammo.value.destroy(trans);

            }

          }

      // Euler code for handling Local Space Motion states. (Key point is to use parent as the physics object is a child node.)

/*
            let globalRotationMatrix = new THREE.Matrix4();
            globalRotationMatrix.makeRotationFromQuaternion(globalRotation);

            // Get the inverse of the parent's world matrix to convert from global space to local space
            let parentInverseMatrix = stateNodes.value[i].parent.matrixWorld.invert();

            // Apply the parent's inverse world matrix to the global rotation matrix to transform it to local space
            let localRotationMatrix = new THREE.Matrix4();
            localRotationMatrix.multiplyMatrices(parentInverseMatrix, globalRotationMatrix);

            // Extract local rotation quaternion from the transformed matrix
            let localQuaternion = new THREE.Quaternion();
            localQuaternion.setFromRotationMatrix(localRotationMatrix);

            stateNodes.value[i].setRotationFromQuaternion(localQuaternion);
*/

//            const localRotation = stateNodes.value[i].quaternion.clone();

            // Get global position from Ammo.js

/*
          // Code for handling global space motion states

          if (motionState) {

            let trans = new Ammo.value.btTransform();
            motionState.getWorldTransform(trans);

            const rotation = trans.getRotation();
            stateNodes.value[i].setRotationFromQuaternion(new THREE.Quaternion(rotation.x(), rotation.y(), rotation.z(), rotation.w()));

            const position = trans.getOrigin();
            stateNodes.value[i].position.set(position.x(), position.y(), position.z());

            Ammo.value.destroy(trans);

          }
*/

        }

      }

      if (doRender.value != true) {

          return;

      } else {

          requestAnimationFrame(render);

      }

    };

    onMounted(() => {

        init();
        createGround();
        // createSphere();

        watch(
            () => rigidBodyCount.value,

            (first, second) => {

                console.log("rigidBodyCount.value " + rigidBodyCount.value)

            }

        );

        // Floor
        // const groundShape = new Ammo.value.btStaticPlaneShape(new Ammo.value.btVector3(0,1,0),1);
/*
        const groundShape = new Ammo.value.btBoxShape(new Ammo.value.btVector3(50, 1, 50));
        const groundTransform = new Ammo.value.btTransform();
        groundTransform.setIdentity();
        groundTransform.setOrigin(new Ammo.value.btVector3(0, -1, 0));

        const groundMass = 0;
        const groundInertia = new Ammo.value.btVector3(0, 0, 0);
        const groundMotionState = new Ammo.value.btDefaultMotionState(groundTransform);
        const groundRigidBodyInfo = new Ammo.value.btRigidBodyConstructionInfo(groundMass, groundMotionState, groundShape, groundInertia);
        groundRigidBodyInfo.m_friction = 0.8;
        const groundRigidBody = new Ammo.value.btRigidBody(groundRigidBodyInfo);
        //physicsWorld.value.addRigidBody(groundRigidBody);
        physicsWorld.addRigidBody(groundRigidBody);
*/

        //physicsWorld.value.setInternalTickCallback((world, timeStep) => {
/*
        physicsWorld.value.setInternalTickCallback((world, timeStep) => {

          console.log('hit tick callback')
          console.log(timeStep)
          // Check rotation values and log information

        });
*/
    });

    onBeforeUnmount(() => {

    });

    return {
      rootNode,
      doRender,
      Ammo,
      THREE,
      width,
      height,
      init,
      stateNodes,
      togglePhysicsVisibility,
      createGround,
      createRigidBody,
      createSphere,
      createBox,
      counter,
      iterations,
      lastTickIterations,
      gravity,
      tStep,
      tLast,
      tIntegrate,
	    tIntegrate,
      currentTime,
      deltaT,
      stepInterval,
      accumulator,
      physicsWorld,
      rigidBodyCount,
      box,
      addRigidBody,
      removeRigidBody,
      setKinematic,
      setDynamic,
      entities,
      render,
      STATE,
      showPhysics,
      COLLISION_FLAGS
    };
  }
};
</script>

<style>
body {
  margin: 0;
}

</style>
