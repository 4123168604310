import {defineStore} from "pinia";

const doFunc = function () {

        // logic
        return "something";

    },
    gameStore = defineStore(
        "game",
        {
            "state": () => ({
                "UI_VIEW": "game",
                "ZOOM_LEVEL": "1", // 0 for close up, 1 = normal, 2 = far, 3 = scene, 4 = global
                "TRANSITIONS": [],
                "SELECTED": [],
                "HOVER": {},
                "transitionId": 0
            }),
            "actions": {
                setUiView(k,v) {

                    this[k] = v;

                },
                setHover(v) {

                    if (!v)
                      return;

                    if (this['HOVER']?.node?.id) {

                        if (v.node.id != this['HOVER'].node.id) {

                            this.unsetHover();

                        }

                    }

                    this['HOVER'] = v;

                },
                unsetHover() {

                    this['HOVER'] = undefined;

                },
                addSelected(v) {

                    this['SELECTED'].push(v);

                },
                remSelected(i) {

                    this['SELECTED'].splice(i,1);

                },
                clearSelected(v) {

                    // add an id reference so we can identify transitions
                    this['SELECTED'] = [];

                },
                /*
                 * Add transition to queue.
                 * args: transition of json struct below
                 * {
                 *  node: the actor object
                 *  position : the intended position (optional)
                 *  quaternion : the intended quaternion rotation (optional)
                 *  alpha : the interpolation speed (default pos,quat 0.1 and 0.01 resp.)
                 * }
                 * return: id of transition, can be used to remove transition
                 */
                addTransition(v) {

                    if (!v.node) {

                        return;

                    }

                    for (var i=0; i < this['TRANSITIONS'].length; i++) {

                        if (v.node.id == this['TRANSITIONS'][i]?.node?.id) {

                            this.remTransition(this['TRANSITIONS'][i].id);

                        }

                    }

                    // add an id for each transition.
                    v.id = this['transitionId']++;

                    this['TRANSITIONS'].push(v);

                    return v.id;

                  /*
                    // force timeout here otherwise quick successive transitions can get stuck competing.
                    setTimeout(
                        () => {

                            console.log("transition timeout")
                            this.remTransition(v.id);

                        },
                        v.alpha * 3000
                    );
                  */
                },
                /*
                 * remove transition from queue.
                 * args: id - id of transition to remove
                 * return: true if found, false if not
                 */
                remTransition(id) {

                    for (var i=0; i < this['TRANSITIONS'].length; i++) {

                        if (this['TRANSITIONS'][i].id === id) {

                            // delete array entry is found
                            this['TRANSITIONS'].splice(i,1);

                            return true;

                        }

                    }

                    return false;

                },
                hasTransition(id) {

                    for (var i=0; i < this['TRANSITIONS'].length; i++) {

                        if (this['TRANSITIONS'][i].id === id) {

                            return true;

                        }

                    }

                    return false;

                }
            },
            "getters": {
                "getState": (state) => function (q) {

                    return state[q];

                }
            }
        }
    );

export { gameStore };

