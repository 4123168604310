
<template>
  <div ref="rootNode" />
</template>

<script>
import { emits, inject, onBeforeUnmount, onMounted, ref } from 'vue';
import * as THREE from 'three';
 import { gameStore } from "@/stores/game";
//import { gameStore } from "@/stores/state";

export default {
  name: 'Actions',
  "components": {
  },
  "props": {
  },
  "emits": [
  ],
  setup (props, {emit}) {
    const scene = inject('scene'),
    game = gameStore(),
    utils = inject('utils'),
    camera = inject('camera'),
    mouseInfo = inject('mouseInfo'),
    rootNode = ref(),
    selected = (i) => {

      if (i?.node?.userData?.cardInfo?.type === "deck") {

        let g = game.getState("UI_VIEW");

console.log("g")
console.log(g)

        if (g == "game") {

           game.setUiView("UI_VIEW", "deck")

        } else {

           game.setUiView("UI_VIEW", "game")

        }

      }
      
    },
    run = (entity) => {

    },
    walk = (entity) => {

    },
    crouch = () => {

    },
    crawl = () => {

    },
    talk = () => {

    },
    idle = () => {

    },
    pistol = (entity) => {

        console.log(entity)
/*

        const walkWeight = 1,
         pistolWeight = 0.5;

        if (!(entity.animations.walk && entity.animations.rightPistol)) {

            console.log('animations missing')
            return;

        }

        const joints = [],
         armBone = utils.findObjectByName(entity.node, 'mixamorigRightArm');
        armBone.traverse((child) => {
            if (child.isBone) {
                joints.push(child.name);
                //child.rotation.set(0, 0, 0);
            }
        });

        console.log(joints)

        const pistolPoseClip = entity.animations.rightPistol,
         walkClip = entity.animations.walk,

         x = new Date();

        for (let i=0; i < pistolPoseClip.tracks.length; i++) {

            if (joints.includes(pistolPoseClip.tracks[i].name.split('.')[0])) {

                for (let j=0; j < walkClip.tracks.length; j++) {

                    console.log("joint included")

                    if (pistolPoseClip.tracks[i].name == walkClip.tracks[j].name) {

                        console.log(`FOUND ${  pistolPoseClip.tracks[i].name}`);
                        walkClip.tracks[j] = pistolPoseClip.tracks[i];
                        continue;

                    }

                }

            }

        }

        console.log(`gen animation time :  ${  new Date() - x}`);
*/
console.log('entity.animations.pistolWalk')
console.log(entity.animations.pistolWalk)

        const aWalk = entity.animationMixer.clipAction(entity.animations.pistolWalk);

        aWalk.play();

    },
    aimAt = (node, vector, dampen) => {

        const bone = utils.findObjectByName(node, 'mixamorigRightArm');

        const target = bone.clone();

        target.position.copy(vector);

        bone.lookAt(target.position);

        bone.rotateX(Math.PI/2);

    },
    aimAt2 = (node, vector, dampen) => {

        const maxAngle = THREE.MathUtils.degToRad(30);

        const bones = utils.findBonesFromRoot(node, 'mixamorigRightHand');

        for (let i = 1; i < 3; i++) {

            const bone = bones[i];

            // Calculate the target vector in local space of the parent bone
            const parentBone = bone.parent;
            const localVector = vector.clone().applyMatrix4(parentBone.matrixWorld.clone().invert());

            // Calculate the rotation needed to aim at the target in local space
            const targetQuaternion = new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(0, 1, 0), localVector.normalize());

            // convert to angle for test
            const topQuaternion = node.quaternion.clone();
            const angle = topQuaternion.angleTo(targetQuaternion);

            if (angle < maxAngle) {

                bone.quaternion.slerp(targetQuaternion, 1/(i*i));

            }

        }

    },
    aim = (node, vector, dampen) => {

        const bone = utils.findObjectByName(node, 'mixamorigRightArm');

        const forwardVector = new THREE.Vector3(0, 0, 1);

        var worldQuaternion = new THREE.Quaternion();
/*
        bone.getWorldQuaternion(worldQuaternion);
*/

        var worldMatrix = bone.matrixWorld;
        const position = new THREE.Vector3();
        const scale = new THREE.Vector3();
        worldMatrix.decompose(position, worldQuaternion, scale);

        const localForwardVector = forwardVector.clone().applyQuaternion(worldQuaternion.invert());
        const forwardEuler = new THREE.Euler().setFromVector3(localForwardVector);

        var aimQuat = new THREE.Quaternion().setFromEuler(forwardEuler);

        const angleX = Math.PI / 2; // Rotation angle (90 degrees)
        const axisX = new THREE.Vector3(1, 0, 0); // X-axis
        const quaternionX = new THREE.Quaternion().setFromAxisAngle(axisX, angleX);
        
        aimQuat.multiply(quaternionX);

        bone.quaternion.slerp(aimQuat, 0.1);

/*
        const armBone = utils.findObjectByName(node, 'mixamorigRightArm');
        if (armBone) {

            if (dampen == true) {

                armBone.rotation.set(0, 0, 0);

            }
            armBone.rotation.copy(vector);

        }
*/
    },
    process = () => {

        // actionsComponent.value.aimAt2(scene.children[5], scene.children[scene.children.length-1].position, true);

    };

    onMounted(() => {

    });

    onBeforeUnmount(() => {

    });

    return {
      game,
      scene,
      rootNode,
      camera,
      mouseInfo,
      process,
      selected,
      run,
      walk,
      crouch,
      crawl,
      talk,
      idle,
      pistol,
      aim,
      aimAt,
      aimAt2
    };

  }

};
</script>

<style>
body {
  margin: 0;
}

</style>
