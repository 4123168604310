
function blendArrays (base, override, blend) {

    if (blend <= 0 || blend >= 1) {

        console.log('blend value must be between 0 and 1')
        return;

    }

    const blended = [];
    let j = 0;

    for (let i = 0; i < base.length; i++) {

        const value = base[i] * (1 - blend) + override[j] * blend;
        blended.push(value);

        j = (j + 1) % override.length;

    }

    return blended;

}

function findObjectByName (node, name) {

    if (node.name == name) {

        return node;

    }

    for (var i=0; i < node.children.length; i++) {

        let obj = findObjectByName(node.children[i], name)
        if (obj)
          return obj

    }

    return;

}

function findBonesFromRoot (node, name, bones, depth) {

    if (!bones) {

        bones = [];
        depth = 0;

    }

    if (node.type == "Bone") {

        bones[depth] = node;
        depth++;

    }

    if (node.name == name) {

        return bones.splice(0, depth).reverse();

    }

    for (var i=0; i < node.children.length; i++) {

        let obj = findBonesFromRoot(node.children[i], name, bones, depth)
        if (obj)
          return obj

    }

    return;

}

export const utils = {
    blendArrays,
    findObjectByName,
    findBonesFromRoot
};

