<template>
  <div>
    <div class="admin-bg-head">

      <div class="admin-contents">

        <div>
          <h1 style="color: #ca7fdd; padding-top: 20px;">
            Admin Panel
          </h1>
        </div>

        <h2>Set Prices</h2>

        <CInput
          class="price-box"
          :value="dysprosiumPrice"
          title="Dysprosium Price"
          :placeholder="localize('DYSPROSIUM_PRICE')"
          @inputValue="dysprosiumPrice = $event"
        />

        <CInput
          class="price-box"
          :value="neodymiumPrice"
          title="Neodymium Price"
          :placeholder="localize('NEODYMIUM_PRICE')"
          @inputValue="neodymiumPrice = $event"
        />

        <CInput
          class="price-box"
          :value="praseodymiumPrice"
          title="Praseodymium Price"
          :placeholder="localize('PRASEODYMIUM_PRICE')"
          @inputValue="praseodymiumPrice = $event"
        />

        <CInput
          class="price-box"
          :value="terbiumPrice"
          title="Terbium Price"
          :placeholder="localize('TERBIUM_PRICE')"
          @inputValue="terbiumPrice = $event"
        />

        <CInput
          class="price-box"
          :value="galliumPrice"
          title="Gallium Price"
          :placeholder="localize('GALLIUM_PRICE')"
          @inputValue="galliumPrice = $event"
        />

        <CInput
          class="price-box"
          :value="germaniumPrice"
          title="Germanium Price"
          :placeholder="localize('GERMANIUM_PRICE')"
          @inputValue="germaniumPrice = $event"
        />

        <CInput
          class="price-box"
          :value="hafniumPrice"
          title="Hafnium Price"
          :placeholder="localize('HAFNIUM_PRICE')"
          @inputValue="hafniumPrice = $event"
        />

        <CInput
          class="price-box"
          :value="indiumPrice"
          title="Indium Price"
          :placeholder="localize('INDIUM_PRICE')"
          @inputValue="indiumPrice = $event"
        />

        <CInput
          class="price-box"
          :value="rheniumPrice"
          title="Rhenium Price"
          :placeholder="localize('RHENIUM_PRICE')"
          @inputValue="rheniumPrice = $event"
        />

      </div>

    </div>

    <div class="admin-bg-main" />

    <CModal
      v-if="showAdminModal"
      width="medium"
      color="#5d328d"
      :title="localize('ADD_CREATOR')"
      @close="showAdminModal = false"
    >
      <template #header>
        <div> Edit Admin </div>
      </template>
      <template #body>
        <div class="flex-col">
          <CInput
            :value="pAdmin"
            :title="localize('TEXT_ADDRESS')"
            :placeholder="localize('PROFILE_NAME_PLACEHOLDER')"
            type="string"
            class="admin-item"
            @inputValue="pAdmin = $event"
          />
          <div
            class="cinputTitle"
          >
            {{ localize('TEXT_ABOUT') }}
          </div>
          <div>
            <div
              class="admin-save-button"
              @click="addAdminOnChain"
            >
              {{ localize('TEXT_ADD_ADMIN') }}
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>
  </div>
</template>
<script>

import {iconStore} from "@/stores/icon";
import {inject, onMounted, reactive, ref, watch} from "vue";
import {translationStore} from "@/stores/translation";
import {rareiumPlatformABI} from "../abi.js";

export default {
    "name": "Admin",
    "components": {
    },
    "props": {
    },
    setup () {

        const translation = translationStore(),
            icon = iconStore(),
            serverConfig = inject("serverConfig"),
            userInfo = inject("userInfo"),
            priceContract = ref("0x820Ac7703768638C5F539aC7C5Ac08d7811c754C"),
            showAdminModal = ref(false),
            pAccount = ref(""),
            pAdmin = ref(""),
            pUserId = ref(""),
            dysprosiumPrice = ref(),
            neodymiumPrice = ref(),
            praseodymiumPrice = ref(),
            terbiumPrice = ref(),
            galliumPrice = ref(),
            germaniumPrice = ref(),
            hafniumPrice = ref(),
            indiumPrice = ref(),
            rheniumPrice = ref(),
            web3 = inject("web3"),
            // plain JS functions
            getIcon = function (i) {

                if (serverConfig.visualMode === "dark") {

                    return icon.get(`${i}_DARK`);

                }
                return icon.get(`${i}_LIGHT`);

            },
            genRandStr = function () {

                return (new Date().getSeconds() * Math.random()).toString(36).replace(
                    ".",
                    ""
                );

            },
            updatePrices = async function () {

                console.log('dysprosiumPrice ' + dysprosiumPrice.valuee);
                console.log('neodymiumPrice ' + neodymiumPrice.value);
                console.log('praseodymiumPrice ' + praseodymiumPrice.value);
                console.log('terbiumPrice ' + terbiumPrice.value);
                console.log('galliumPrice ' + galliumPrice.value);
                console.log('germaniumPrice ' + germaniumPrice.value);
                console.log('hafniumPrice ' + hafniumPrice.value);
                console.log('indiumPrice ' + indiumPrice.value);
                console.log('rheniumPrice ' + rheniumPrice.value);

                if (web3.value.utils.isAddress(platformAAddress.value) === true &&
                  web3.value.utils.isAddress(platformBAddress.value)) {

                    const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                        addr = accounts[0],

                        amc = new web3.value.eth.Contract(
                            rareiumPlatformABI,
                            `${serverConfig.VUE_APP_PLATFORM_ADDRESS}`
                        );
                    let gasLimit = await amc.methods.setDefaultFees([
                            platformAAddress.value,
                            platformAFee.value,
                            platformBAddress.value,
                            platformBFee.value
                        ]).estimateGas({"from": addr,
                            "gas": 100000}),

                        suggestion_gas = await web3.value.eth.getGasPrice();
                    suggestion_gas = Math.floor(suggestion_gas * 1.2);

                    gasLimit = gasLimit <= 300000
                        ? Math.floor(gasLimit * 4)
                        : Math.floor(gasLimit * 1.5);

                    amc.methods.setDefaultFees([
                        platformAAddress.value,
                        platformAFee.value,
                        platformBAddress.value,
                        platformBFee.value
                    ]).send({
                        "from": addr,
                        "gasPrice": web3.value.utils.toHex(suggestion_gas),
                        gasLimit
                    }).
                        then((jsonRpcResult) => {

                            console.log("jsonRpcResult");

                        });

                } else {

                    alert("please provide a vaid ethereum address");

                }

            },
            addAdminOnChain = async function () {

                if (pAdmin.value) {

                    console.log("${serverConfig.VUE_APP_PLATFORM_ADDRESS}");
                    console.log(`${serverConfig.VUE_APP_PLATFORM_ADDRESS}`);

                    if (web3.value.utils.isAddress(pAdmin.value) === true) {

                        const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                            addr = accounts[0],

                            amc = new web3.value.eth.Contract(
                                rareiumPlatformABI,
                                `${serverConfig.VUE_APP_PLATFORM_ADDRESS}`
                            );
                        let gasLimit = await amc.methods.addRareiumAdmin(pAdmin.value).estimateGas({"from": addr,
                                "gas": 100000}),

                            suggestion_gas = await web3.value.eth.getGasPrice();
                        suggestion_gas = Math.floor(suggestion_gas * 1.1);

                        gasLimit = gasLimit <= 300000
                            ? Math.floor(gasLimit * 4)
                            : Math.floor(gasLimit * 1.5);

                        amc.methods.addRareiumAdmin(pAdmin.value).send({
                            "from": addr,
                            "gasPrice": web3.value.utils.toHex(suggestion_gas),
                            gasLimit
                        }).
                            then((jsonRpcResult) => {

                                console.log("jsonRpcResult");
                                showAdminModal.value = false;
                                // addAdmin();

                            });

                    } else {

                        alert("please provide a vaid ethereum address");

                    }

                }

            },
            hideModalEvent = function () {

                showCreatorModal.value = false;

            };

        onMounted(() => {

            watch(
                () => pAccount.value,
                () => {

                    findCreator(pAccount.value);

                }
            );
            watch(
                () => userInfo.accounts,
                () => {

                }
            );

        });

        return {"localize": translation.localize,
            "icon": icon.get,
            getIcon,
            showAdminModal,
            pAccount,
            pAdmin,
            pUserId,
            dysprosiumPrice,
            neodymiumPrice,
            praseodymiumPrice,
            terbiumPrice,
            galliumPrice,
            germaniumPrice,
            hafniumPrice,
            indiumPrice,
            rheniumPrice,
            updatePrices,
            genRandStr,
            serverConfig,
            userInfo};

    }
};

</script>
<style scoped>
h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  font-weight: bold;
}
input.admin-avatar {
  display: none;
}
.subtitle {
  max-width: 500px;
  padding: 20px;
  font-size: 3em;
  font-size: 1.0em;
  line-height: 30px;
  text-align: left;
}
a.contactus {
  color: #322e6a;
  text-decoration: underline;
  cursor: pointer;
}
.admin-buttom-end {
  display: flex;
  flex-direction: row;
  align-items: end;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.admin-bg-head {
  position: relative;
  width: 100%;
  background-size: cover;
}
.admin-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  background: white;
  border: 1px #e766ff solid;
  display: flex;
}
.admin-bg-main {
  position: relative;
  width: 100%;
  background: #fff;
}
.admin-card-content {
  text-align: left;
  display: flex;
  flex-direction: row;
}
.admin-contents {
  width: 70%;
  padding-top: 50px;
  margin-left: auto;
  margin-right: auto;
}
img.admin-card-avatar {
  width: 128px;
  height: 128px;
}
.admin-save-button {
  display: flex;
  margin: 10px;
  align-items: center;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: white;
  height: 50px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 15px 20px 2px rgb(139 106 224 / 33%);
  cursor: pointer;
  width: fit-content;
  background: linear-gradient(338deg,#ff7c88,#ff46a4)
}
.admin-modal {
  width: 700px;
}
.c-grid .o-flex-grid--item {
  background: #fff;
}
.o-flex-grid {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin: 0 auto;
  overflow: auto;
}

.o-flex-grid--item-a {
  overflow: auto;
  width: 5%;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);
  justify-content: center;
  margin: 5px;
  padding: 20px;
}

.o-flex-grid--item-b {
  overflow: auto;
  width: 30%;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);
  justify-content: center;
  margin: 5px;
  padding: 20px;
}

.o-flex-grid--item-c {
  overflow: auto;
  width: 30%;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);
  justify-content: center;
  margin: 5px;
  padding: 20px;
}

.o-flex-grid--item-d {
  overflow: auto;
  width: 15%;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);
  justify-content: center;
  margin: 5px;
  padding: 20px;
}

.o-flex-grid--item-e {
  cursor: pointer;
  overflow: auto;
  width: 15%;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);
  justify-content: center;
  margin: 5px;
  padding: 20px;
}

.o-flex-grid--item-f {
  overflow: auto;
  color: red;
  cursor: pointer;
  width: 10%;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);
  justify-content: center;
  margin: 5px;
  padding: 20px;
}

.w-100 {
  width: 100%;
}

@media(max-width:800px)
.o-flex-grid {
  background: white;
  flex-direction: column;
  flex-wrap: nowrap;
}

.o-flex-grid--item {
  width: 100% !important;
}
.admin-box {
  margin-top: 50px;
  margin-bottom: 50px;
  background: white;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #9f71e7;
}
.news-image-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  margin: 10px;
}
.file-label {
  cursor: pointer;
  width: 50px;
  height: 50px;
  padding: 12px 20px;
  margin: -2px 0px;
  font-size: .9em;
  box-sizing: border-box;
  border: 2px solid #dfdfdf;
  border-radius: 15px;
  background-image: url("../assets/default-image-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
}
input[type=file].news-image-input {
  display: none;
}
.price-box {
  width: 300px;
  margin-right: 10px;
}
.flex-row-end {
  display: flex;
  flex-direction: row;
  align-items: end;
}
.home-news-container-desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  height: 250px;
  width: 100%;
  display: flex;
  background-image: url("../assets/news-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #f8faff;
  margin-left: auto;
  margin-right: auto;
}
.news-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  margin-right: 70px;
  max-height: 250px;
  overflow: hidden;
}
.home-news-image {
  margin: 10px 10px 10px 70px;
  background-size: contain;
  height: 128px;
}
</style>

